@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap);

:root {
  --clr-primary-200: #0747a6;
  --clr-primary-400: #fa4f55;

  --clr-neutral-100: #fff;
  --clr-neutral-900: #222c2a;

  --ff-primary: "Roboto", sans-serif;
  --ff-accent: "Lato", sans-serif;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  font-family: "Roboto", sans-serif;
  font-family: var(--ff-primary);
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.6;
  background: #f2f2f2;
}

body,
h1,
h2,
h3,
p {
  margin: 0;
}

h1,
h2,
h3 {
  font-family: "Roboto", sans-serif;
  font-family: var(--ff-primary);
  font-weight: 900;
  line-height: 1;
  margin-bottom: 1rem;
}

h2,
h3,
p {
  margin-bottom: 1em;
}
h1 {
  font-size: 18px;
  line-height: 1.3;
}
a {
  color: #0052cc;
  text-decoration: none;
}

a:focus {
  outline: 2px solid #4c9aff;
}

img {
  display: block;
  max-width: 100%;
}

.text-center {
  text-align: center;
}
.ml {
  margin-left: 10px;
}
.page {
  margin-top: 65px;
}

.split {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.split.reverse {
  flex-direction: column-reverse;
}
.alignStart {
  align-items: flex-start;
}
.justifyStart {
  justify-content: flex-start;
}

.activeColor {
  color: green !important;
}
.HomeCompanyAppsWrapper {
  display: flex;
  flex-wrap: wrap;
}

.container {
  margin: 0 auto;
  width: min(90%, 70.5rem);
}
.container--narrow {
  max-width: 34rem;
  margin: 0 auto;
}

.bg-light {
  background-color: #0747a6;
  background-color: var(--clr-primary-200);
}

.bg-primary {
  color: #fff;
  color: var(--clr-neutral-100);
  background-color: #fa4f55;
  background-color: var(--clr-primary-400);
}

.bg-dark {
  color: #fff;
  color: var(--clr-neutral-100);
  background-color: #222c2a;
  background-color: var(--clr-neutral-900);
}

.bg-primary h2,
.bg-dark h2 {
  color: #0747a6;
  color: var(--clr-primary-200);
}

.botton {
  padding: 5px 15px;
  background: #fa4f55;
  background: var(--clr-primary-400);
  color: #fff;
  text-decoration: none;
  font-size: 1rem;
  border-radius: 5px;
}

/* section start */
section {
  padding: 15px;
  flex-grow: 1;
}
.section {
  flex-grow: 1;
}
.homeSection {
  padding: 0;
}
.section-heading {
  font-size: 30px;
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 3rem;
  color: #023a77;
}
.section-heading span:first-child {
  color: #1b4962;
  display: block;
}
.section-heading span:last-child {
  color: #fa4f55;
  color: var(--clr-primary-400);
  display: block;
}
/* section start */

/* Sidebar start */

.sideBar {
  position: fixed;
  left: 0;
  height: 100%;
  width: 25%;
  display: flex;
  flex-direction: column;
  background: linear-gradient(
    141deg,
    rgba(250, 80, 85, 1) 0%,
    rgba(157, 0, 198, 1) 100%
  );
  padding: 30px;
  justify-content: space-between;
  color: #fff;
}

.sidebarList {
  display: flex;
  flex-direction: column;
  margin-top: 5rem;
}

.nav_button {
  color: #fff;
  font-size: 14px;
  text-decoration: none;
  margin-bottom: 5px;
  padding: 5px 15px;
  border-radius: 4px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.nav_button:hover {
  background: rgb(0 0 0 / 9%);
}

.nav_button.active_menu {
  background: rgb(0 0 0 / 40%);
}

.nav_button_icon {
  display: inline-block;
  margin-right: 10px;
}

.seperator {
  border-bottom: 1px solid #e6e6e6;
  margin: 10px 0;
}

.drawerBotton {
  position: absolute;
  bottom: 50px;
  right: 0;
  background: #fff;
  width: 30px;
  height: 30px;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
  color: #a104c2;
  font-weight: bold;
  padding: 3px 10px;
  cursor: pointer;
}

.helpButton {
  height: 35px;
  width: 35px;
  background: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  margin-bottom: 10px;
}

.sidebarProfileButton {
  height: 35px;
  width: 35px;
  background: #f94f56;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  margin-bottom: 10px;
  color: #ffffff;
  position: relative;
}

.sidebarProfileButton:hover .sidebarProfileSettings {
  display: block;
}

.sidebarProfileSettings {
  display: none;
  position: absolute;
  background: #fff;
  left: 35px;
  width: 150px;
  padding: 10px;
  font-size: 14px;
  flex-direction: column;
}

.sidebarProfileSettings a {
  display: block;
}

/* Sidebar end */
/* Login Page  */
.fullPage {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.fullPageImage {
  flex: 5 1;
  height: 100%;
  display: flex;
  align-items: flex-start;
  padding: 2rem;
  flex-direction: column;
}

.fullPageform {
  flex: 4 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.loginFormWrapper {
  padding: 30px;
  min-width: 300px;
}

.loginLightMessage {
  color: rgb(94, 108, 132);
  text-align: center;
  font-size: 14px;
}

.loginDarkMessage {
  font-weight: bold;
  text-align: center;
  font-size: 14px;
  color: rgb(94, 108, 132);
  margin-bottom: 30px;
}

.loginLogo {
  max-width: 200px;
  margin: 0 auto;
  margin-bottom: 30px;
}

.loginPageLinks {
  font-size: 14px;
}

.loginHeading {
  margin-bottom: 2rem;
}

.loginBanner {
  max-width: 500px;
  margin-top: 5rem;
}

/* Welcome page  */
.SignupWelcomePage {
  margin: 0 auto;
  padding: 15px;
  text-align: center;
  max-width: 500px;
}

.SignupWelcomePage img {
  max-width: 200px;
  margin: 0 auto;
}

/* Notification list  */
.notifList {
  display: flex;
  margin: 30px 0;
  flex-wrap: wrap;
  flex-direction: column;
}

.notifList > div {
  padding: 5px 10px;
  margin: 5px 0;
  background: #fff;
  border-radius: 8px;
  font-size: 14px;
}

/* List start  */
.List {
  display: flex;
  margin: 15px 0;
  flex-wrap: wrap;
}

.listHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.formList {
  display: flex;
  margin: 15px 0;
  flex-wrap: wrap;
  justify-content: flex-end;
}

/* List end  */
.selectBox {
  margin-bottom: 10px !important;
}

/* Card  start*/
.detailHeading {
  font-size: 12px;
  font-weight: bold;
}

.detailValue {
  font-size: 14px;
}

.notificationCard {
  color: #222c2a;
}

.notificationHeading {
  font-weight: bold;
}

/* Card end */

/* Details pages */
.detailsItem {
  display: flex;
  padding-bottom: 0.5rem;
  font-size: 13px;
}

.detailsKey {
  font-weight: bold;
  text-transform: capitalize;
  min-width: 100px;
}

/* Details pages */

.TableHeader {
  font-weight: bold !important;
}

.features {
  padding: 3em 0;
}

.buttons {
  margin-left: 1em;
}

.hero {
}

.hero-body {
  display: flex;
}
.dashboardItemWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.dashboardItemWrapper a {
  display: flex;
  min-width: 170px;
  text-align: center;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 14px;
  margin: 5px;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  color: #000;
  font-size: 14px;
}
.dashboardItemWrapper a > img {
  max-width: 100px;
  margin-bottom: 15px;
}
.hero-links {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.content {
  max-width: 250px;
  min-width: 250px;
  padding: 10px;
  margin: 10px;
  border: 1px solid #f2f2f2;
  background: #f2f2f2;
}

/* Forms */
.formSeperator {
  margin-top: 50px;
}

.form-item-wrapper {
  margin-bottom: 10px;
}

.formContainer {
  padding: 15px;
}

.searchUserBox {
  margin-top: 30px;
  padding: 15px;
  border: 1px solid #ccc;
}

.searchUserFormWrap {
  display: flex;
  justify-content: space-between;
}

.successText {
  color: green;
  font-size: 14px;
}

.failureText {
  color: red;
  font-size: 14px;
}

.formConfirmationMsg {
  margin: 10px 0;
  font-size: 16px;
}

.bold {
  font-weight: bold;
}

.verticalSpacer15 {
  width: 15px;
  display: inline-block;
}

.FormInput {
  min-width: 320px !important;
}

.detailsDrawer {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: calc(100% - 230px);
  padding: 75px 20px;
  background: #fff;
  z-index: 99;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  overflow-y: scroll;
}

.detailsCloseBtn {
  position: absolute !important;
  right: 0;
  top: 0;
}

.DataGridHeading {
  font-weight: bold;
}

.link {
  color: #0645ad !important;
}

.help.is-danger {
  color: red;
}

.MuiDataGrid-columnHeaderTitle {
  font-weight: bold !important;
}

.paper {
  padding: 15px;
}

.collapsed {
  width: 50%;
}

/* Config Page */

.configPageWrapper {
  display: flex;
}

/* IBC specific styles */
.UserHeader {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  height: 50px;
  background: #ddd;
  display: flex;
  align-items: center;
  padding: 10px;
  z-index: 9;
}

.businessServicesWrapper {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 15px;
}

.UserPageWrapper {
  margin-top: 50px;
  margin-bottom: 50px;
  width: calc(100% - 240px);
  margin-left: auto;
}

.UserFooterItem {
  flex: 1 1;
  text-align: center;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.UserFooterItem img {
  width: 40px;
  margin: 0 auto;
}

/* Ticket app  */
.TicketdetailsDrawer {
  position: fixed;
  top: 50px;
  right: 50px;
  bottom: 50px;
  left: 50px;
  padding: 27px 20px;
  background: #fff;
  display: grid;
  box-shadow: 0px 0px 0px 9999px rgb(0 0 0 / 50%);
  grid-template-columns: 60% 35%;
  grid-gap: 30px;
  grid-gap: 30px;
  gap: 30px;
  z-index: 99;
}

.ticketDetailsWrapper {
  margin-right: 2%;
  border: 1px solid #c7c7c7;
  padding: 15px;
  max-height: 100%;
  overflow: scroll;
  margin-top: 25px;
}

.ticketsDocumentWrapper {
  padding-top: 15px;
}

.ticketsCommentWrapper {
  width: 80%;
  padding-top: 30px;
}

.ticketDetailsCommentItem {
  background: rgb(241, 241, 241);
  padding: 5px 10px;
  margin-bottom: 5px;
  border-left: 3px solid #0747a6;
  border-radius: 20px;
}

.ticketDetailsCommentHeader {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgb(240, 240, 240);
}

.ticketDetailsCommentUser {
  color: #0052cc;
}

.ticketDetailsCommentMetaWrap {
  font-size: 0.7rem;
}

.ticketAddBtn {
  position: absolute !important;
  top: 84px;
  right: 30px;
}

.ticketDetailsDocumentItem {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #cfcfcf;
  align-items: center;
  padding: 5px 0;
  margin-bottom: 5px;
}

.ticketDetailsSaveBtn {
  position: absolute;
  left: 10px;
  bottom: 10px;
}

.ticketDetailsCommentFormWrap {
  padding: 15px 0;
}

.ticketDetailsCommentFormWrap .input {
  margin-bottom: 10px;
}

#TicketTitle {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 15px;
}

.scrolable {
  overflow-y: scroll;
  padding-right: 30px;
  height: calc(100% - 30px);
}

.Document-form-item-wrapper {
  padding: 15px 10px;
  background: #efefef;
}
.TicketsPage {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 15px;
  gap: 15px;
  margin-top: 30px;
}
.TicketTablePaper {
  padding: 10px;
}
.ticketTagHeadingWrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.ticketTagAddButtonWrap {
  display: flex;
  justify-content: center;
  align-items: center;
}
.ticketsDocumentItem {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  border: 1px solid #dedede;
  padding: 5px;
  text-align: center;
}
.ticketsDocumentItem .pg-viewer-wrapper {
  height: 150px;
  width: 120px;
  overflow: hidden;
  margin: 0 auto;
  padding-bottom: 10px;
  border-bottom: 1px solid #dedede;
}
.projectListCardName,
.sprintListCardName {
  padding: 5px 0;
}
.ticketsDocumentItemWrapper {
  display: flex;
  grid-gap: 15px;
  gap: 15px;
  flex-wrap: wrap;
  margin-bottom: 15px;
}
.ticketsDocumentItemName {
  padding-bottom: 10px;
  font-size: 10px;
}
.ticketsDocumentItem .pg-viewer-wrapper .pg-viewer {
  width: 100%;
}
.ticketsDocumentItemBtnWrapper {
  display: flex;
}
.ticketCreateProjectForm {
  display: flex;
  margin-bottom: 10px;
  padding: 10px;
  grid-gap: 6px;
  gap: 6px;
}
.ticketProjectSearch {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.scrolable {
  overflow-y: scroll;
  padding-right: 30px;
  margin-bottom: 50px;
}

.UserOrgConfirmationPageWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 10px;
}

.UserOrgConfirmationPageWrapper img {
  width: 200px;
}

.redirect_message {
  font-size: 13px;
  margin-top: 15px;
}

.PaymentPopUpWrapper {
  text-align: center;
  max-width: 300px;
  margin: 0 auto;
  padding-top: 30px;
}

.PaymentPopUpIcon {
  padding: 15px;
  background: #f94f55b6;
  width: 100px;
  margin: 0 auto;
  margin-bottom: 15px;
  border-radius: 25px;
}

.PaymentPopUpTitle {
  font-weight: bold;
  font-size: 20px;
  color: rgb(22, 22, 22);
  margin-bottom: 10px;
}

.PaymentPopUpDes {
  font-size: 12px;
  margin-bottom: 30px;
  line-height: 1.3;
}

.PaymentPopUpEyebrow {
  background: #d1e9f8;
  width: -webkit-max-content;
  width: max-content;
  color: #59b1e6;
  padding: 3px 10px;
  border-radius: 25px;
  font-size: 12px;
  margin: 0 auto;
  margin-bottom: 10px;
}

.PaymentPopUpWrapper .price {
  color: #0747a6;
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 30px;
}

.PaymentPopUpTerms {
  font-size: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.PaymentPopUpWrapper button {
  padding: 10px 30px;
  border-radius: 6px;
  background: #0747a6;
  border: unset;
  color: #fff;
  font-weight: bold;
  margin-top: 15px;
  cursor: pointer;
}

/* Notifications */

.NotificationData {
  font-size: 14px;
  margin-bottom: 15px;
}

.NotificationDetail {
  font-size: 16px;
}

.NotificationLabel {
  font-weight: bold;
}

.NotificationStatus {
  margin-bottom: 15px;
  color: #00c853;
}

.TableHeading .MuiTableCell-head {
  font-weight: bold;
}

.PostListFeaturedImage {
  width: 50px;
  height: 50px;
}

/* Support/help page  */
.HelpPageWrapper {
  margin: 30px;
  max-width: 80%;
}

.HelpCategoryWrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  grid-gap: 30px;
  gap: 30px;
  padding: 30px;
  background: #f2f2f2;
}

.HelpCategoryItem {
  min-width: 280px;
  border-radius: 3px;
  box-shadow: 0 0 1px 0 rgb(23 43 77 / 24%);
  padding: 15px;
  background: #fff;
  display: flex;
  grid-gap: 30px;
  gap: 30px;
  max-width: 49.5%;
  cursor: pointer;
}

.HelpCategoryItem:hover {
  background-color: #e8f0fe;
}

.HelpCategoryItem img {
  width: 100px;
}

.HelpCategoryItemTitle {
  font-size: 20px;
  font-weight: bold;
  color: #333;
  margin-bottom: 15px;
  line-height: 1;
}

.HelpCategoryItemText {
  font-size: 14px;
  color: #333;
  font-weight: 100;
}

/* Faq page  */
.FaqPageWrapper {
  margin: 30px;
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.rdw-editor-wrapper {
  box-sizing: content-box;
  border: 1px solid #d1d1d1;
  min-height: 300px;
  box-sizing: content-box;
  width: 100%;
  height: 100%;
  padding: 10px;
}

.userEditorWrapper {
  border: 0;
  padding: 0;
}
/* Govt schemes */
.govtSchemeCategoryWrapper {
  padding: 10px;
}
.categoryLink:hover {
  color: #0052cc;
  font-weight: bold;
}
/* Course  */

.CourseGrid {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 30px;
  gap: 30px;
}
.CourseDetailsWrapper {
  background: #eaf5ff;
  padding: 15px;
  margin-top: 15px;
  font-size: 14px;
  margin-bottom: 30px;
}
.CourseModuleWrapper {
  background-color: unset !important;
  box-shadow: unset !important;
  margin-top: 15px;
}

.CourseModuleTumbnail {
  display: grid;
  grid-template-columns: 100px 1fr;
  grid-gap: 10px;
  gap: 10px;
  padding: 10px;
  font-size: 14px;
  border-bottom: 1px solid #e4e4e4;
  cursor: pointer;
}
.CourseModuleTumbnail.Active {
  background-color: #e8f0fe;
}
.CourseModuleTitle {
  font-weight: bold;
  margin-top: 10px;
}
.CourseModuleTitleTestBtn {
  margin-top: 30px;
}
.CourseWrapperflex {
  display: flex;
  grid-gap: 15px;
  gap: 15px;
  flex-wrap: wrap;
  justify-content: center;
}
.courseLineItemAdmin {
  /* display: flex;
  gap: 15px; */
  margin-bottom: 30px;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  /* align-items: center; */
  padding: 10px;
}
.my_course_card_wrap {
  display: flex;
  grid-gap: 15px;
  grid-gap: 15px;
  gap: 15px;
  align-items: center;
}
.courseLineItemAdmin img {
  width: 200px;
}
.courseLineItemAdminBtnWrap {
  display: flex;
}
.courseAddChaptersForm {
  padding: 20px 0;
}
.visionMissionItem {
  text-align: left;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  padding: 15px;
  overflow: hidden;
  min-height: 180px;
  position: relative;
  margin-bottom: 15px;
}
.visionMissionItem h2 {
  font-size: 30px;
  margin-bottom: 10px;
  margin-top: 15px;
}
.visionMissionItem p {
  font-size: 16px;
}
.visionMissionItem img {
  position: relative;
  right: 0;
  width: 50%;
}
.video_player {
  width: calc(100% + 30px);
  margin-left: -15px;
  margin-right: -15px;
}
.correctAnswerLabel {
  padding-top: 10px;
  display: inline-block;
  font-size: 11px;
}
.QAoptions {
  -webkit-column-count: 2;
          column-count: 2;
  padding: 0;
  padding-bottom: 30px;
}
.CourseQaAnswer {
  color: red;
}
.CourseQaAnswer.Correct {
  color: green;
}
.QAbox {
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  margin-top: 10px;
}
.QApageSection {
  margin: 30px 0;
}
.PageBannerWrapper {
  grid-gap: 15px;
  gap: 15px;
  margin-bottom: 15px;
  margin-top: 15px;
}

.PageBanner {
  background: #fff;
  border-radius: 15px;
  padding: 20px;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}

.PageBanner img {
  display: none;
}

.PageBanner h1 {
  font-size: 18px;
  margin-bottom: 5px;
}

.PageBanner p {
  font-size: 12px;
}
.Homepage {
  background: #fff;
}
.homePageBanner {
  display: flex;
  background: #fff;
  padding: 30px 5px;
  align-items: center;
  justify-content: space-between;
  background-image: url(/static/media/Edwinbannnerbg.c9f3beee.jpg);
  background-size: cover;
  margin-top: 65px;
  color: rgb(0, 0, 0);
  background-repeat: no-repeat;
}
.homePageBanner h1 {
  font-size: 30px;
  line-height: 1;
  color: #023a77;
}

.homePageBanner p {
  color: #023a77;
  font-size: 14px;
}
.BannerWithGradient {
  background: linear-gradient(91.54deg, #ddd4e7 35.77%, #ce9ca6 72.08%);
  box-shadow: 0px 0px 12px 4px rgba(0, 0, 0, 0.04);
  border-radius: 20px !important;
}
.HomeCompanyAppsSection {
  padding: 15px 0;
}
.HomeServicesWrapper {
  grid-gap: 15px;
  gap: 15px;
  justify-content: space-between;
  display: flex;
  flex-wrap: wrap;
}

.HomeServicesCard {
  padding: 15px;
  width: 47%;
  cursor: pointer;
}
.HomeServicesCard h2 {
  margin-bottom: 10px;
}

.HomeServicesCard:hover {
  background-color: #e8f0fe;
}

.HomeServicesCard img {
  max-width: 80px;
  margin-bottom: 10px;
}
.homeSection {
  padding: 3rem 0;
}
.homeEnquireFormSection {
  background: #ebf5ff;
  padding: 0;
}
.homeEnquireForm {
  padding: 2rem 30px;
}
.requestSuccessWrapper img {
  max-width: 150px;
  margin: 0 auto;
}

.requestSuccessWrapper h1 {
  text-align: center;
  font-size: 25px;
}

.requestSuccessWrapper p {
  text-align: center;
}

.requestSuccessDetailsWrapper {
  margin-top: 30px;
  text-align: center;
}

.requestSuccessDetailsWrapper .amount {
  padding: 10px 30px;
  border: 1px solid #c7c7c7;
  display: inline-block;
  border-radius: 6px;
}

.requestSuccessDetailsWrapper .amount .lable {
  font-size: 10px;
  color: #cbcbcb;
}

.requestSuccessDetailsWrapper .amount .figure {
  font-size: 16px;
  font-weight: bold;
}

.HomeCompanyAppsWrapper {
  margin-bottom: 15px;
}

.companyProfileBannerWrapper {
  margin-bottom: 15px;
}

.HomeCompanyAppsItem {
  padding: 10px;
  text-align: center;
  width: 120px;
  cursor: pointer;
}

.HomeCompanyAppsItem:hover {
  background-color: #e8f0fe;
}

.HomeCompanyAppsItem img {
  max-width: 35px;
  margin: 0 auto;
}

.HomeCompanyProfileCard {
  min-width: 280px;
  justify-content: space-between;
  grid-gap: 30px;
  gap: 30px;
  font-size: 12px;
  font-weight: bold;
  display: flex;
  background-image: url(/static/media/businesscard.efffb6c4.png);
  color: #fff !important;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  cursor: pointer;
  padding: 20px;
  border-radius: 20px !important;
}

.HomeCompanyProfileCard img {
  max-width: 60px;
  align-self: flex-end;
}

.HomeCompanyProfileCard .CompanyName {
  text-transform: capitalize;
  font-weight: bold;
  font-size: 18px;
}
.HomeCompanyProfileCard .OwnerName {
  font-weight: 400;
  margin-bottom: 30px;
}
.HomeCompanyProfileCard .AddressTitle {
  font-weight: bold;
  font-size: 14px;
}
.HomeCompanyProfileCard .Address {
  font-weight: 400;
}

.companyProfileBanner {
  display: flex;
  align-items: center;
}

.ProfileImageWrap {
  min-width: 175px;
  text-align: center;
  font-weight: bold;
  font-size: 15px;
}

.companyProfileBanner img {
  max-width: 80px;
  margin: 0 auto;
}

.companyProfileBanner .ProfileBasicsWrap {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  padding: 10px;
}

.companyProfileBanner .ProfileBasicsWrap > div {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 20px 0;
}

.ProfileBasicsItem span:first-child {
  color: #a7a7a7;
  padding-right: 10px;
}

.ProfileBasicsItem span:last-child {
  font-weight: bold;
  color: #333;
}
.homeServicesCardWrapper {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 10px;
  gap: 10px;
  margin-bottom: 30px;
}
.homeServicesCard {
  background: #fff;
  width: 48%;
  padding: 10px;
  box-shadow: 0px 0px 5px 5px rgb(26 26 26 / 6%);
  border-radius: 15px;
  text-align: center;
  min-width: 135px;
}
.homeServicesCardHeading {
  font-size: 12px;
  font-weight: bold;
}
.homeServicesCard img {
  max-width: 100px;
  margin: 0 auto;
}

.ProfileBasicsWrap {
  padding: 15px;
}
.postImage {
  width: 100%;
}

.ToolsCard {
  background: #fff;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  padding: 10px;
}
.CategoryHeading {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 15px;
}
.categoryLink {
  display: block;
  padding: 10px 0;
  color: #000;
  border-top: 1px solid #d6d6d6;
  font-size: 14px;
}

/* Investor app  */
.invRequestDetailsDrawer {
  position: fixed;
  left: 10%;
  right: 10%;
  top: 15%;
  bottom: 10%;
  background: #fff;
  padding: 2rem 1rem;
  box-shadow: 0px 0px 0px 9999px rgb(0 0 0 / 50%);
  overflow: scroll;
}
.InvReqWorkQueueCardWrapper {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 10px;
  gap: 10px;
}
.InvReqWorkQueueCard {
  min-width: 250px;
  max-width: 300px;
  padding: 10px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
}
.InvReqWorkQueueCardTitle {
  font-weight: bold;
  font-size: 20px;
  padding-bottom: 10px;
}
.InvReqWorkQueueCardDetails > span {
  font-weight: bold;
}
.invReqDetailsLabel {
  font-weight: bold;
}
.invReqDetailsData {
  font-size: 15px;
}
.noDataMessage {
  text-align: center;
}

@media (min-width: 40em) {
  h1 {
    font-size: 24px;
  }
  .split {
    flex-direction: row;
  }
  .split.reverse {
    flex-direction: row;
  }
  .split > * {
    flex-basis: 100%;
  }

  .split > * + * {
    margin-left: 2em;
  }
  .page {
    display: flex;
    margin-top: 65px;
  }
  .PageBanner {
    display: flex;
  }
  .PageBannerWrapper {
    margin-bottom: 0 !important;
  }
  .HomeServicesWrapper {
    flex-direction: row;
    justify-content: flex-start;
  }
  .CourseGrid {
    display: grid;
    grid-template-columns: 1fr 300px !important;
    grid-gap: 30px;
    grid-gap: 30px;
    gap: 30px;
    padding-top: 30px;
  }
  .homePageBanner {
    display: flex;
    padding: 20px;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
    background-position: 100% 100%;
    background-size: cover;
    margin-top: 65px;
    height: 80vh;
    background-repeat: no-repeat;
    color: #222c2a !important;
    color: var(--clr-neutral-900) !important;
  }
  .HomeServicesCard {
    padding: 10px;
    min-width: 138px;
    max-width: 200px;
    cursor: pointer;
  }
  .PageBanner img {
    display: block;
    max-width: 220px;
  }
  .visionMissionItemWrapper{
    align-items: unset;
  }
  .visionMissionItem {
    text-align: left;
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
      0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
    padding: 15px;
    overflow: hidden;
    min-height: 180px;
    position: relative;
  }
  .visionMissionItem h2 {
    font-size: 30px;
  }
  .visionMissionItem p {
    font-size: 14px;
  }
  .visionMissionItem img {
    right: 0;
    width: 20%;
  }
  .video_player {
    width: 100%;
    min-width: 798px;
    max-height: 450px;
    margin: 0;
  }
}

